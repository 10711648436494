import React from 'react';
import Card from '../Card/Card.js';
import './CardRow.css';

function CardRow(props) {

	return (
		<div className="cardHolder">
			{props.people.map(n => 
				<Card key={n.name} 
					name={n.name} 
					imgSrc={n.img} 
					role={n.role}
					gradYear={n.gradYear}
					email={n.email}
					committee={n.committee}
					project={n.project}
					tree={n.tree}
					leaf={n.leaf}
					linkedin={n.linkedin}
					resume={n.resume}
					website={n.website}
					blurb={n.blurb}
				/>
			)}
		</div>
	)
	
}

export default CardRow;
