/* ========================================
 * ========= All Current Members ==========
 * ========================================
 */
const current_members = [
  {
    name: "Aditi Mundra",
    role: "Project Leader",
    gradYear: "Spring 2027",
    classOf: "Fall 2023",
    blurb: "helloo! i'm a sophomore studying EECS and i love trying new foods and sleeping.",
    email: "aditimundra@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/aditi-mundra/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alan Wei",
    role: "Project Leader",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "hello! i'm a first year studying CS + Math. some of my hobbies include finding internships, fixing my resume, and occasionally laughing. lemme know if you tryna lock in 🤓",
    email: "awei001@berkeley.edu",
    linkedin: "linkedin.com/in/thealanwei",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alana Yang",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Fall 2023",
    blurb: "I watched someone puke on someone's phone. ",
    email: "alanayang@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alena Chao",
    role: "Vice President",
    gradYear: "Spring 2026",
    classOf: "Spring 2023",
    blurb: "hallo my name is alena and im a junior in eecs!! talk to me abt being a god in brawl stars, being trash at badminton, enhypens latest album, or literally anything else :D looking forward to meeting u!!",
    email: "alenachao@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/alenachao",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alex Chen",
    role: "Senior Advisor",
    gradYear: "Spring 2025",
    classOf: "Fall 2022",
    blurb: "i like taylor swift",
    email: "alexchen4@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/alexxcchen/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alicia Gullon",
    role: "Project Leader",
    gradYear: "Spring 2026",
    classOf: "Spring 2024",
    blurb: "Hi, I'm Alicia! I like judo, art, music, and yummy desserts :D.",
    email: "aliciakgullon@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/alicia-gullon/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Allan Chen",
    role: "Active Member",
    gradYear: "Fall 2024",
    classOf: "Fall 2022",
    blurb: "i am currently a senior studying CS and DS! i love beef noodle soup and am quite spontaneous, so if you love to gig around at 2 am, i'd be down!",
    email: "allanchen511@berkeley.edu",
    linkedin: "linkedin.com/in/allanchen51",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alvin Lin",
    role: "Project Leader",
    gradYear: "Spring 2027",
    classOf: "Fall 2024",
    blurb: "hii im a sophomore from the south bay studying math + cs :))\n\nlikes: music, tea, reading, green, manga, cardistry, sleeping at diabolically late hours\ndislikes: rain, loud noises",
    email: "alvinl@berkeley.edu",
    linkedin: "linkedin.com/in/alvinllin/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Amber Gupta",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Spring 2024",
    blurb: "hi I'm an intended data science major graduating in 2027! I like reading, embroidering, and the ocean. sharks are my favorite fish 𓆝 𓆟 𓆞",
    email: "ambergupta@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Andrei Cojocaru",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Spring 2024",
    blurb: "Hey! I’m Cojo! Coming from Romania to Berkeley. Though my favorite activity is sleeping, I also enjoy going out and coding(rarely).",
    email: "cojoandrei03@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/andrei-cojocaru-57a340214/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Andrew Choy",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "I'm Andrew and I'm a first year studying Data Science. I play soccer, basketball, and I make music. Tap in @ Drewskii on Spotify.",
    email: "andrew_choy@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Andrew Choy",
    role: "President",
    gradYear: "Spring 2026",
    classOf: "Fall 2023",
    blurb: "I can squat 600, bicep curl 75. ",
    email: "andrewchoy@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Angel Mau",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2022",
    blurb: "I like lifting and console gaming!",
    email: "angelmau@berkeley.edu",
    linkedin: "www.linkedin.com/in/angelmau",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Angela Lee",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2024",
    blurb: "nerd for psychology and CS, love playing piano, singing, swimming, travelling for the food, and looking up at the stars!",
    email: "angiev_2517@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/angela-lee-3b148b224",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Angie Baik",
    role: "Member Relations Director",
    gradYear: "Spring 2027",
    classOf: "Fall 2023",
    blurb: "Hi! I’m Angie a sophomore studying Applied Math and CS. I love baking, puzzles, and watching movies/shows.",
    email: "23angieb@berkeley.edu",
    linkedin: "http://linkedin.com/in/angie-baik",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Anna Chung",
    role: "Project Leader",
    gradYear: "Spring 2025",
    classOf: "Fall 2022",
    blurb: "heyo :D! my name is anna and i'm a senior studying computer science & data science! i love rats 🐀, space & physics🌌, machine learning 👩🏻‍💻, music, and art (the picture is from a murakami exhibit)!",
    email: "ajchung@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/annajchung/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Annie Xu",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2022",
    blurb: "hi! i like volleyball, napping, sushi, and going on adventures",
    email: "annie.xu2025@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Avo",
    role: "Mascot",
    gradYear: "??",
    classOf: "",
    blurb: "I like guac, Codeology, and being cute! I can be seen tabling with Codeology members — feel free to come by, say hi, and give me a pat <3",
    email: "info@codeology.club",
    linkedin: "https://www.linkedin.com/company/berkeley-codeology/mycompany/",
    img: "",
    committee: null,
    project: null,
    tree: "Persea americana",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Brooke Huynh",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Spring 2025",
    blurb: "hi, i’m brooke! i’m a junior studying stats and data science. i love doing crosswords and cafe hopping",
    email: "brooke_huynh@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Christina Yue",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2023",
    blurb: "I can bench 225.",
    email: "christinayue@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Claire Su",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "hi!! i'm claire and i'm a freshman majoring in EECS. i love painting, my cat, making playlists, and matcha :D",
    email: "claire_su@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/clairetingsu",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Colleen Pangilinan",
    role: "Project Leader",
    gradYear: "Spring 2026",
    classOf: "Spring 2023",
    blurb: "",
    email: "colleenpangilinan@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/colleen-pangilinan-292241247",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Danny Tran",
    role: "Education Director",
    gradYear: "Spring 2025",
    classOf: "Spring 2023",
    blurb: "Hi! I am Danny, a fourth year CS student from the San Fernando Valley. I really enjoy naturey things like camping and taking walks!",
    email: "dannyltran@berkeley.edu",
    linkedin: "www.linkedin.com/in/danny-tran-2764101a5",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Dara Lin",
    role: "Project Leader",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "bello! i like collecting blind boxes and jellycats, eating pasta and dancing!",
    email: "daralin@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Dean Shahin",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "hey, i'm a freshman studying cs. i like video games, football, and anime, but talk to me about anything :D",
    email: "deanshahin@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Dhruv Ganesh",
    role: "Social Director",
    gradYear: "Fall 2025",
    classOf: "Spring 2022",
    blurb: "If u mention Taco Bell plots I’m already down",
    email: "dganesh@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Emma Zhong",
    role: "Project Leader",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "hello 🤗 i am a freshman studying data science ! i like energy drinks, tv shows, and gamepigeon darts ❤️",
    email: "emma_zhong@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/emmajzhong/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Esme Can",
    role: "Media Director",
    gradYear: "Spring 2027",
    classOf: "Spring 2024",
    blurb: "Hii im Esme! I am double majoring in sociology and computer science! I love music, going to concerts, thrifting, and taking naps :))",
    email: "2233esme@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Esther Shen",
    role: "Senior Advisor",
    gradYear: "Fall 2025",
    classOf: "Spring 2023",
    blurb: "hello!! i’m a senior studying CS and Linguistics. i like languages, nails, hair, and fueling my concert-going addiction ⊂( ´ ▽ ` )⊃",
    email: "esther.shen@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Ethan Herrera",
    role: "Tech Director",
    gradYear: "Spring 2025",
    classOf: "Fall 2022",
    blurb: "hi i’m ethan. i’m a senior studying eecs and this is my sixth semester in cody. some things i like are music production, weight lifting, and movies/anime. i bleed avocado and id die for avo.",
    email: "ethanherrera@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/ethanherrera",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Euhan Kim",
    role: "Senior Advisor",
    gradYear: "Spring 2025",
    classOf: "Fall 2021",
    blurb: "Hi, I'm Euhan! Play league with me :)) and gimme free food plz",
    email: "euhan123@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/euhan-kim/",
    img: "",
    committee: null,
    project: null,
    tree: "Cynthia Lan",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Grant Liu",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "Hello, I am Grant from Shanghai. I love TECH, Cars, and aviation.",
    email: "grant.liu@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Hailey Pham",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Spring 2024",
    blurb: "i love gym, nails, and watermelon sour patches d:",
    email: "haikleyy@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/haileyphham",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Heidy Hernandez",
    role: "Project Leader",
    gradYear: "Spring 2027",
    classOf: "Spring 2024",
    blurb: "hi hi",
    email: "hheidy_463@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/heidyhernandezjuan/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Henrique Rodrigues",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2024",
    blurb: "Ask me about movies, soccer, music, and dressing up as spider man for birthday parties",
    email: "hrdevfs@gmail.com ",
    linkedin: "http://linkedin.com/in/henrique-rrodrigues",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: "hrdevfs.com"
  },
  {
    name: "Jack Kumashiro",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Fall 2022",
    blurb: "Hi! I'm Jack and I'm a fourth year EECS major from Arlington, VA. I love trying new foods, chilling with friends and really anything outside. I'm also on the Cal Triathlon team. Fun Fact, I'm a twin :)",
    email: "jkwakecal25@berkeley.edu",
    linkedin: "www.linkedin.com/in/jack-kumashiro",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Jaewon Lee",
    role: "Web Director",
    gradYear: "Spring 2025",
    classOf: "Spring 2023",
    blurb: "hey everone, im jaewon! when im not coding away or working on psets, i love going on hikes, playing tennis, or spontaneously going on trips out to sf :D",
    email: "jaewon.lee@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/thejaewonlee/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "John Bragado",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Fall 2024",
    blurb: "hey! i’m john and i’m studying ds + physics. i love all things data, computing, and graphics.",
    email: "johnbragado@berkeley.edu",
    linkedin: "http://linkedin.com/in/johnbragado/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "John Glen Siy",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2023",
    blurb: "I can't bench 225.",
    email: "johnglen_siy@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/john-glen-siy-055805228/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Jolin Zhu",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Spring 2025",
    blurb: "i get down. i don’t play.",
    email: "jozhu@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/jolin-zhu-657207303?trk=contact-info",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Justin Xu",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2022",
    blurb: "hi! i love meeting people, trying new things, fruit, sports and not writing bios.",
    email: "justinxu@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/justin-xu-556b6521b/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Karen Gong",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "hi i'm karen! some things i love are art/painting, cafe hopping, sunset chasing, pinterest doom scrolling, yapping, and napping :d",
    email: "karengong@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Kate Trevor",
    role: "Project Leader",
    gradYear: "Spring 2025",
    classOf: "Fall 2023",
    blurb: "I won gold at junior olympics soccer.",
    email: "katestrevor@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: "Jada",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Katelyn Jo",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2023",
    blurb: "Heyyy I'm Katelyn, and I'm studying Data Science and Cognitive Science. I am a SoCal girlie from Orange County where I love to go to amusement parks, do some window shopping at malls, and walk around the beach. Up here, I am learning ukulele, binging shows and movies, and going on drives around the Bay Area. My dream is to one day work in tech for the entertainment/media industry!",
    email: "katelyn.jo@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/katelyn-jo/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Kelly Yeh",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Fall 2022",
    blurb: "hii! i love going on walks, good food, and you :p",
    email: "kellyyyeh@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/kelly-yeh-b08217232/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Kerrine Tai",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2022",
    blurb: "Hi I'm Kerrine! I like plants and my hamster Potato :)",
    email: "kerrine@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Kevin Hu",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Spring 2024",
    blurb: "Hello! My name is Kevin I am a Sophomore intended in studying Data Science, I am a passionate film photographer and I love hiking!",
    email: null,
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Khue Do",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "heyy so an easier way to say my name is 'kwei' :) im a international student from vietnam studying data science rn. i love trying different ice cream flavor, binge watch kdrama/zombie movie and sleep!!!",
    email: "khue_do@berkeley.edu",
    linkedin: "www.linkedin.com/in/khue-do-523515247",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Liz Weaver",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2022",
    blurb: "hii!! i'm liz :) sometimes i dance and bake. i also love picnics and good eats",
    email: "elizabeth.weaver@berkeley.edu",
    linkedin: "www.linkedin.com/in/e-j-weaver",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Maithreya Gowda",
    role: "Social Director",
    gradYear: "Spring 2027",
    classOf: "Fall 2024",
    blurb: "I like food, music, thrifting",
    email: "maithreya@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Maxwell DeFanti",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "My name is Max. I like chess. I like guitar. I like Codeology.",
    email: "maxwell.defanti@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/maxdefanti/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Miller Liu",
    role: "Tech Director",
    gradYear: "Spring 2027",
    classOf: "Fall 2023",
    blurb: "I can run a 4 minute mile.",
    email: "millerliu@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: "David",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Minjune Kim",
    role: "Senior Advisor",
    gradYear: "Spring 2025",
    classOf: "Spring 2023",
    blurb: "My best friend is Victor Zhou.",
    email: null,
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Natalie Wei",
    role: "Sourcing Director",
    gradYear: "Spring 2026",
    classOf: "Spring 2023",
    blurb: "hi im natalie, a junior studying cs and linguistics! i like reading and doing crosswords",
    email: "nataliewei@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/natalie-wei/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Nithya Appannagaari",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "hii i love making tea, cafe hopping, and photography 📸 ",
    email: "nithya.app@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/nithyaappannagaari/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Philip Ye",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2023",
    blurb: "I've never puked on someone's phone. ",
    email: "philipye314@gmail.com",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Phillip Chen",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2023",
    blurb: "inting",
    email: "phillipe10042003@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/phillipuchen/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Rachel Ho",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "interested in ml and robotics! outside of cs I like to dance and knit :)",
    email: "racheleho@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/rachel-ho-273320323/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Rachel Sang",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Fall 2024",
    blurb: "Hi, I'm Rachel, and I am studying bioengineering and music! I love yapping 24/7, making niche internet references, and penguins.",
    email: "rachelsoobinsang@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/rachelsoobinsang/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Raghav Punnam",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Spring 2023",
    blurb: "Hey, I'm Raghav! I love playing basketball, working out, and doing spontaneous things :) ",
    email: "raghavpu@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/raghav-punnam/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Riley Reichel",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Fall 2023",
    blurb: "I'm a junior studying EECS, interested in SWE and robotics. I like volleyball, reading, listening to music, and playing board games.",
    email: "rileyreichel@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Rohan Gopalam",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Spring 2025",
    blurb: "i like soccer, music, and cory hall",
    email: "rohangopalam@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/rohan-gopalam",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Rohan Gulati",
    role: "Sourcing Director",
    gradYear: "Spring 2026",
    classOf: "Spring 2023",
    blurb: "My favorite restaraunt is Tony's Style",
    email: "rohangulati@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Roy Chebaclo",
    role: "Active Member",
    gradYear: "Fall 2027",
    classOf: "Spring 2025",
    blurb: "What's up, I'm Roy a CS major from Minnesota. I play on the Cal Ice Hockey team and love working out, playing poker, listening to music, and eating good food. ",
    email: "rchebaclo@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/roymchebaclo/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Sahir Tandon",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "Hi! I'm Sahir, a first-year studying EECS. When I'm not grinding, I love going on hikes, doing origami, and playing board games.",
    email: "sahir.tandon@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/sahir-tandon",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Sajiv Kumar",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Fall 2024",
    blurb: "Hi, I'm Sajiv! Im a sophomore studying CS! I love hiking, music, art, and reading!",
    email: "sajivkumar@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Samhita Ghosh",
    role: "Media Director",
    gradYear: "Spring 2026",
    classOf: "Spring 2024",
    blurb: "hi im samhita! i love eating (lets cook tgt :D), karaoke and binging romcom kdramas <3",
    email: "samhita.ghosh@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/samhita-ghosh/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Sanghun Byun",
    role: "Active Member",
    gradYear: "Spring 2026",
    classOf: "Spring 2025",
    blurb: "Hi, I'm a third year majoring in CS and English. I'm from Korea. In my free time I like to go to cafes or explore new places in Berkeley. ",
    email: "26sbyun@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/sanghun-byun-32143124a/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Sarah Kiefer",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Fall 2022",
    blurb: "Heyy! I'm a senior studying Econ and Data Science. I love to cook, dance like nobody is watching at 3am, and just talk :) Fun fact: I grew up in the midwest so I know how to ride a horse but not a bike",
    email: "sarahkiefer@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/sarah-kiefer-60965422a/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Shing Gao",
    role: "Education Director",
    gradYear: "Spring 2026",
    classOf: "Fall 2023",
    blurb: "hi my name is shing and i am a bioe + eecs major. i love nature and my cat and i have lots of photographs of both. i have a complex relationship with pop music. ask me about my notes app ;p",
    email: "shinggao@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/shinggao/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Shriyaa Narayanan",
    role: "Active Member",
    gradYear: "Spring 2027",
    classOf: "Spring 2025",
    blurb: "Hi! I'm Shriyaa! I love writing books, tennis, and dance!!",
    email: "shriyaanarayanan@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Stephane Finot",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "Hello! I’m Stephane and I’m a first year studying Applied Math + CS. I like to play soccer and edit videos sometimes too. #1 Food Enjoyer",
    email: "stephane_finot@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/stephanefinot",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Timothy Park",
    role: "Finance Director",
    gradYear: "Spring 2026",
    classOf: "Spring 2023",
    blurb: "I like baseball.",
    email: "timmypark@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Ting-Yong Liu",
    role: "Member Relations Director",
    gradYear: "Spring 2028",
    classOf: "Fall 2024",
    blurb: "Hey smart fellas and fart smellas! I'm a first year data science major and I love making your mom jokes.",
    email: "tingyongliu@berkeley.edu",
    linkedin: "www.linkedin.com/in/tingyongliu",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Victoria Nguyen",
    role: "Active Member",
    gradYear: "Spring 2025",
    classOf: "Spring 2022",
    blurb: "lover of minecraft, milk, and mushrooms <33",
    email: "victoriahnguyen@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/victoriianguyen/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: "https://victoriianguyen.github.io/"
  },
  {
    name: "Victoria Ruan",
    role: "Active Member",
    gradYear: "Spring 2028",
    classOf: "Spring 2025",
    blurb: "hello! i love spicy food <3",
    email: "fengyi_ruan@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Vivek Putta",
    role: "Project Leader",
    gradYear: "Spring 2027",
    classOf: "Spring 2024",
    blurb: "Hi, my name is Vivek! I love to play Smash, basketball, or just chill out with some movies and pizza.",
    email: "Vivekputta@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Xina Wang",
    role: "Project Leader",
    gradYear: "Spring 2027",
    classOf: "Fall 2023",
    blurb: "Heyo!! I'm Xina and I'm a sophomore studying chemical biology and intending DS. Road work ahead? _   _ _ _ _   _ _ _ _   _ _   _ _ _ _.",
    email: "xina.wang@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/xina-wang/",
    img: "",
    committee: null,
    project: null,
    tree: "Stephen",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Yunze Du",
    role: "Active Member",
    gradYear: "Fall 2024",
    classOf: "Spring 2023",
    blurb: "let's chat about programming, economics, data, but also specialty coffee, guitar, and which aquarium is the best on earth!",
    email: "yzdu@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/yunzedu/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  }
];

/* ========================================
 * ========= All Alumni/Inactive ==========
 * ========================================
 */
const alumni_members = [
  {
    name: "AJ DeMarinis",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "I'm a 61B TA so come to my OH",
    email: null,
    linkedin: "https://www.linkedin.com/in/andrew-demarinis-96b76a241",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Aarushi Shah",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "hi i’m aarushi!! i love playing nyt connections, dancing, and hiking. my hot take is that i don’t love the taste of avocados…",
    email: null,
    linkedin: "https://www.linkedin.com/in/aarush1shah",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Alyssa Smith",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "Hi!! I'm a junior intending on studying CS/DS. I like word games, drinking ungodly amounts of caffeine, and listening to sad boi indie rock :'-)",
    email: "alyssa.427@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/alyssa-smith-physics/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Amy Huang",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "I love tasty pot, smoothie, hot cheeto puffs, animal crossing, moffitt 1st floor!!! ",
    email: "amyxjhuang@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/amyxjhuang/",
    img: "",
    committee: "Web!",
    project: null,
    tree: "Anna",
    leaf: "Tiffanie <3 :3",
    resume: "https://tinyurl.com/amyhuangresume",
    website: "https://amyhuang.web.app"
  },
  {
    name: "April Zhang",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "Hi, I’m April! I’m a junior studying EECS and minoring in Philosophy. Talk to me about cooking, music recs, kickboxing, my two thriving avocado plants, or life in general! (☞ ͡° ͜ʖ ͡°)☞",
    email: "april.zhang@berkeley.edu",
    linkedin: "https://www.linkedin/in/aprilczhang/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Ashley Chu",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "hi! :^) i like egg tarts and movies. as you can see the name ashley is >>>",
    email: "ashchu@berkeley.edu",
    linkedin: "https://linkedin.com/in/ashley-chu",
    img: "",
    committee: "Website",
    project: null,
    tree: "Ada",
    leaf: "Maya",
    resume: null,
    website: "https://ashchu.github.io/"
  },
  {
    name: "Ashwin Parthasarathy",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "Hey, I'm Ashwin! I love getting new music recommendations, taking road trips, going to concerts, spending the whole day in movie theaters, and making bad sports predictions.",
    email: null,
    linkedin: "https://www.linkedin.com/in/ashwin-parthas/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Ava Shah",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "I love IEOR and Moffitt",
    email: null,
    linkedin: "https://www.linkedin.com/in/ava-shah-03709773",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "CJ Manna",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "Talk to me about hiking, movies, and ethical design!",
    email: "charlesmanna@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: "Micah",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Chanbin Park",
    role: "Alumni",
    gradYear: "Freshman",
    classOf: "",
    blurb: "I don't like avocados cuz they're too mushy. Can we change our mascot?",
    email: "chanbin.park@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/chanbin-park-4b795920b/",
    img: "",
    committee: null,
    project: null,
    tree: "Jun Hee Han",
    leaf: null,
    resume: null,
    website: "https://chanbinpark.com/"
  },
  {
    name: "Charles Ming",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "i like playing chess + poker, making no progress at rsf, reading, and trying weird foods",
    email: "charlesming@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/charlesming/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Chetan Khanna",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "",
    email: "chetan.khanna@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Cynthia Lan",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "Hi I’m Cynthia! I like going to the beach, spontaneous trips and card games!",
    email: "cynthialan@berkeley.edu",
    linkedin: null,
    img: "",
    committee: "Social Committee",
    project: "Sea Warden, Build-A-Bear",
    tree: "Kristie",
    leaf: "Euhan",
    resume: null,
    website: null
  },
  {
    name: "Danica Tang",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "hi! I’m Danica, a junior studying CS <3 I love taking pictures, crafting (recently in my slime phase), playing instruments, cooking, painting, and traveling!",
    email: "danicat@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/danica-t",
    img: "",
    committee: null,
    project: null,
    tree: "Maya Zheng",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "David Lim",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: ":D",
    email: "david.lim@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/davidlim7391/",
    img: "",
    committee: "Media",
    project: "Sea Warden",
    tree: "Satiya Kem",
    leaf: "Rachel Xin",
    resume: null,
    website: "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
  },
  {
    name: "David Qing",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "hey! give me your playlists, i need new music :)",
    email: "davidqing@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/davidqing/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Dustin Luong",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "",
    email: "dstn.luong@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Eric Berndt",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "I love asking questions and learning new things :D",
    email: "ekberndt@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/ekberndt/",
    img: "",
    committee: null,
    project: "ClassifAI",
    tree: "Ali Matsumoto",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Ernest Goh",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "",
    email: "ernest.goh20@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: "Jun Hee Han",
    leaf: "Mizuho Li",
    resume: null,
    website: null
  },
  {
    name: "Grace Juan",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "Hey, I'm Grace! I'm a sophomore intending to major in computer science. I like tennis, piano, art, games, and music.",
    email: "grace.wj@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Jada Klein",
    role: "Alumni",
    gradYear: "Sophmore",
    classOf: "",
    blurb: "Heyo -- I'm a freshman studying CS. I like outdoor adventures, chill music, and yummy food :)",
    email: "jadaklein03@gmail.com",
    linkedin: "https://www.linkedin.com/in/jada-klein/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Joelle Siong Sin",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "Hi! My name is Joelle, I’m a senior studying IEOR.  I’m interested in product management and the intersection of CS and Operations Research! Happy to chat more about changing majors, PM internships, or any similar interests!",
    email: "jsiongsin@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/joellesiongsin/",
    img: "",
    committee: null,
    project: null,
    tree: "Sami!",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Joshua Aubin",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "Hi! I like bouldering, pc games, smash, and music. Hmu if you need a climbing buddy :)",
    email: "joshaubin@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/joshaubin/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Kamille Dyan Fernando",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "",
    email: "kamillef@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: "Tiffany",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Kevin Chow",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "You know that one friend that should obviously win a Darwin Award? That’s me. I’m that friend. I’m an EECS senior transfer from OC, CA. I love to hang out with Codeology fam and just have fun in general. HMU and I’d likely be down.",
    email: "chowmein113@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/kevin-chow-engineer",
    img: "",
    committee: null,
    project: "Beary hungry",
    tree: "Trevor Baba",
    leaf: "I’m a leaf",
    resume: null,
    website: null
  },
  {
    name: "Kylie Ren",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "Hi! I like going on photoshoots, playing valorant, studying plants/animals and making arts & crafts (or anything aesthetic lol)! ",
    email: "kxyr@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Lauren Zheng",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "I love skiing, camping, Thai food, and playing the guitar! I'm from San Jose and my family just adopted a Corgi this summer  ♡ ♥ 🐶 ",
    email: "laurenz@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/lauren-zheng-2606",
    img: "",
    committee: null,
    project: "Build-a-bear",
    tree: "Daniel Tseng",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Lincoln Too",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "I like anime, sports, and board games. HMU!",
    email: "lincoln99@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: "ClassifAI",
    tree: "Ahmed",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Mallika Parulekar",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "test Hi! I'm Mallika - a sophomore studying computer science who's also interested in math, economics and the law. When I'm not drinking boba and playing tennis, you can find me building things, trying my hand at fullstack and being a human learning about machine learning :)",
    email: "mallika.parulekar@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/mallikaparulekar/",
    img: "",
    committee: null,
    project: "Sea Warden",
    tree: "Aarushi",
    leaf: "Ramya Chitturi",
    resume: "https://drive.google.com/file/d/1JF8Vr_nSIWZkEu12A21TLXBcvM4YatlS/view?usp=sharing",
    website: null
  },
  {
    name: "Michael Zhang",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "coffee, frank, n friends !!! say hi :)",
    email: "zhang.michael@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Mizuho Li",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "Hi I’m Mizuho! I love  playing any random sports and a BIG fan of k drama. ",
    email: "limizuho@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/mizuho-li",
    img: "",
    committee: null,
    project: "Build a Bear",
    tree: "Ernest Goh",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Navi Emiliano",
    role: "Alumni",
    gradYear: null,
    classOf: "Spring 2024",
    blurb: "hihiii, i like going to eat, playing volleyball, and i’m currently into stardew valley :DD",
    email: null,
    linkedin: "https://www.linkedin.com/in/navi-emiliano-785603201/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Oliver Carmont",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "Heyy! I'm a sophomore from Spain, who loves playing soccer, running and binging on cheez-its.",
    email: "ocarmont@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/oliver-carmont/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Pradeep Muthaiya",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "CS and Stats Sophomore from SoCal just vibing. Hmu if ur in LA and wanna grab food :)",
    email: null,
    linkedin: null,
    img: "",
    committee: null,
    project: "Shares^2",
    tree: "Krishna, Shaylan, Kyle, Karthick, Sree, Vaib, etc.",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Pradyun Kumar",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "hi I like guacomole and anime and Kpop and listening to piano while I study",
    email: "pradyunkumar@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Rachel Xin",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "Hello! I'm Rachel, and I am an EECS major from Orange County, CA. I enjoy playing tennis, baking/cooking, and trying new things during my free time.",
    email: "rachelxin@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/rachelxin",
    img: "",
    committee: null,
    project: null,
    tree: "David Lim",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Razvan Turcu",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "👋I am Raz, a full-stack developer passionate about technology and entrepreneurship. In my free time, I practice 🥊kickboxing, play 🎾tennis, and cook 🍳food.",
    email: "razztech@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/razztech",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Ria Jain",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "sunset lover and coffee enthusiast :)",
    email: null,
    linkedin: "https://www.linkedin.com/in/riajain230",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Sadyant Behera",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "i am huge (true)",
    email: "sadyant.behera@berkeley.edu",
    linkedin: "https://linkedin.com/in/sadyant",
    img: "",
    committee: null,
    project: "sea warden",
    tree: "shaylan dias",
    leaf: null,
    resume: null,
    website: "https://GitHub.com/sadyant"
  },
  {
    name: "Sami Oh",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "I'm a junior interested in web design and development! I love cats, painting, and sitting in the sun :)",
    email: "haotiany@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/samantha-oh-3315b31b7/",
    img: "",
    committee: null,
    project: "Build-A-Bear",
    tree: "Grace Altree",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Satiya Kem",
    role: "Alumni",
    gradYear: "Senior",
    classOf: "",
    blurb: "",
    email: null,
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Sebastiaan Szafir",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "I love the number 42, and I think you should too. I'm from Spain, love programming, board games, anime and have growing suspicions towards Berkeley's parking meters.",
    email: "sebastiaan@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/sebastiaan-szafir-fridman/",
    img: "",
    committee: null,
    project: "ClassifAI",
    tree: "Ethan",
    leaf: "None yet",
    resume: null,
    website: "https://sebiszafir.com/"
  },
  {
    name: "Shannon Or",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "Hi, I'm Shannon! I like playing basketball, snowboarding, and driving to 99ranch. Most importantly, if I could only eat one carb for the rest of my life it would be bread.",
    email: "shannonor@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: "beary hungry",
    tree: "Kyle Hua",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Suhani Singhal",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "hiii! I'm Suhani! I'm a third year studying EECS and Business. I love watching comedy shows and sci-fi films, trying new recipes, and listening to music with friends :) ",
    email: "suhanisinghal04@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Tavleen Bhatia",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "Heyo! I'm a sophomore studying MCB and Data Science. I love working out, trying out new recipes, dreaming of owning a pet cat, and lying on the glade in the sun :)) Hmu to obsess over Grey's Anatomy or share Spotify playlists!",
    email: "tavleenbhatia@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/tavleen-bhatia-498491217/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Tiffanie Chau",
    role: "Alumni",
    gradYear: "Sophomore",
    classOf: "",
    blurb: "Love to watch sunsets, yummy new foods, and good company(people in Codeo)!This will be my 5th semester in Codeology 💕",
    email: "tiffaniechau@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/tiffanielchau/",
    img: "",
    committee: null,
    project: "Beary Hungry",
    tree: "ONLY THE BEST: AMY HUANG",
    leaf: "not ready to be a mother yet...",
    resume: null,
    website: null
  },
  {
    name: "Tiffany Feng",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "Hi! I'm Tiffany, and I'm a Junior studying Computer Science and Business. I'm from San Diego, and I love being outdoors, baking, and traveling :)",
    email: "tiffanylinyifeng@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/tiffanylinyifeng",
    img: "",
    committee: null,
    project: "Beary-Hungry",
    tree: "Angela Jiang",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Tommy Hang",
    role: "Alumni",
    gradYear: "Sophmore",
    classOf: "",
    blurb: "THATS CRAZY! If you ever want to eat at Crossroads, I'll always eat with you. I love music, fencing, basketball, other stuff, and you <3",
    email: "tommyhang@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/tommy-hang-69a433212/",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: "https://www.instagram.com/imatomster/"
  },
  {
    name: "Trevor Baba",
    role: "Alumni",
    gradYear: "Junior",
    classOf: "",
    blurb: "I love playing basketball and collecting NFTs.",
    email: "trevor.baba@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/trevor-baba-000/",
    img: "",
    committee: null,
    project: "Pro-Bono",
    tree: "Sherman",
    leaf: null,
    resume: null,
    website: null
  },
  {
    name: "Trevor Trinh",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "hey! I like learning about people and eating good food. Most of all, I like tommy :)",
    email: "trevortrinh@berkeley.edu",
    linkedin: "https://linkedin.com/in/trevortrinh",
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: "https://trevz.me"
  },
  {
    name: "Victor Zhou",
    role: "Alumni",
    gradYear: null,
    classOf: "",
    blurb: "Reach out to me with any questions about pursuing software engineering!",
    email: "shuaivzhou@berkeley.edu",
    linkedin: null,
    img: "",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    resume: null,
    website: null
  }
];

/* ========================================
 * =========== Process & Export ===========
 * ========================================
 */
// How every role should be ordered
const role_priority = ["President", "Vice President", "Tech Director", 
  "Education Director", "Social Director", "Media Director", "Member Relations Director",
  "Finance Director", "Web Director", "Sourcing Director", "Senior Advisor", 
  "Project Leader", "Mascot", "Active Member"];
const officers = role_priority.slice(0, role_priority.length - 3);

function compare(a, b) {
  // Role priority is primarily for
  // 1) Ordering the officers
  // 2) Letting Avo be at the top of Active Members
  const role_ind_a = role_priority.indexOf(a.role);
  const role_ind_b = role_priority.indexOf(b.role);

  if (role_ind_a < role_ind_b) {
      return -1;
  } else if (role_ind_a > role_ind_b) {
      return 1;
  } else {
      const a_name = a.name.toUpperCase();
      const b_name = b.name.toUpperCase();
      if (a_name < b_name) {
          return -1;
      }
      if (a_name > b_name) {
          return 1;
      }
      return 0;
  }
}

// Adding images using a function
const insertImg = (members) => {
  for (let i = 0; i < members.length; i++) {
    var member = members[i];

    // Needed elements to construct file path
    const name = member.name.toLowerCase().replaceAll(" ", "_");
    const class_of = member.classOf !== "" ? member.classOf.replace(" ", "_") + "/" : "";
    
    try {
      member.img = require("../images/members/compressed/" + class_of + name + ".jpg");
    } catch {
      member.img = require("../images/members/raw/default.jpg");
    }
  }
};
insertImg(current_members);
insertImg(alumni_members);

// Creating the lists needed
export const leadership = current_members.filter(member => officers.indexOf(member.role) !== -1);

export const projectLeaders = current_members.filter(member => member.role === "Project Leader");

export const actives = current_members.filter(member => (member.role === "Active Member" || member.role === "Mascot"));

// TEMPORARY: Our resource contacts are the senior advisors
export const resourceContacts = current_members.filter(member => member.role === "Senior Advisor");

// Sorting the lists
leadership.sort(compare);
projectLeaders.sort(compare);
actives.sort(compare);
resourceContacts.sort(compare);